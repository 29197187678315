import { globalNotificationShow } from 'components/globalStyles/animation';

import styled from 'styled-components/macro';

interface ScNotification {
  error?: boolean;
  warning?: boolean;
  info?: boolean;
  success?: boolean;
}

export const ScGlobalTooltip = styled.div<ScNotification>`
  position: fixed;
  bottom: 60px;
  right: 20px;
  font-size: 14px;
  display: inline;
  align-items: center;
  background: ${({ theme }) => theme.colors.layer.layer01}
    url(${({ error, success }) =>
      error
        ? ({ theme }) => theme.errorIcon
        : success
          ? ({ theme }) => theme.successIcon
          : ({ theme }) => theme.infoIcon})
    no-repeat 20px 50% / 30px;
  box-shadow: 4px 6px 12px rgba(50, 50, 50, 0.2);
  border-radius: 10px;
  padding: 17px 16px 17px 64px;
  z-index: 999999999;
  color: ${({ theme }) => theme.colors.text.primary};
  animation: ${globalNotificationShow} ease 0.5s;
  border: 1px solid
    ${({ theme, error, success }) =>
      error
        ? theme.colors.border.danger
        : success
          ? theme.colors.border.success
          : theme.colors.border.info};
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    bottom: 30px;
    left: 17px;
    right: 17px;
  }
`;
