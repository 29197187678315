import * as React from 'react';
import { SVGProps } from 'react';

const SvgWalletIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5056_14686)">
        <path d="M18.333 5.833h.834v8.334h-.834v2.5a.833.833 0 0 1-.833.833h-15a.833.833 0 0 1-.833-.833V3.333A.833.833 0 0 1 2.5 2.5h15a.833.833 0 0 1 .833.833v2.5Zm-1.666 8.334h-5a4.167 4.167 0 1 1 0-8.334h5V4.167H3.332v11.666h13.333v-1.666ZM17.5 12.5v-5h-5.834a2.5 2.5 0 0 0 0 5H17.5Zm-5.834-3.333h2.5v1.666h-2.5V9.167Z" />
      </g>
    </svg>
  );
};
export default SvgWalletIcon;
