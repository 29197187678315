import React, { FC, useEffect } from 'react';

import cx from 'classnames';

import useAnimationTransition from 'hooks/animationTransition';
import { useTheme } from 'hooks/theme';

import { bodyScrollLockToggle } from 'helpers/common';

import { Icon } from 'components/UI/Icon';
import { Portal } from 'components/portal';

import * as Styled from './ModalFrame.styled';
import { ModalFrameProps } from './types';

const ModalFrame: FC<ModalFrameProps> = ({
  children,
  isOpen,
  onClose,
  isVisibleBottomTabs,
}) => {
  const { colors } = useTheme();
  const { transitionState, isAnimationActive } = useAnimationTransition(
    300,
    isOpen
  );

  useEffect(() => {
    bodyScrollLockToggle(isOpen);
  }, [isOpen]);

  if (!isOpen && !transitionState && !isAnimationActive) return null;

  return (
    <Portal>
      <Styled.Frame
        className={cx('', {
          open: isAnimationActive,
          isVisibleBottomTabs: isVisibleBottomTabs,
        })}
      >
        {onClose && (
          <Styled.CloseBtn onClick={onClose}>
            <Icon
              name="CloseIcon"
              width={32}
              height={32}
              fill={colors.icons.primary}
            />
          </Styled.CloseBtn>
        )}
        <Styled.ScrollableContent>{children}</Styled.ScrollableContent>
      </Styled.Frame>
    </Portal>
  );
};

export default ModalFrame;
