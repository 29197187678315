import React, { FC, useEffect, useState } from 'react';

import cx from 'classnames';

import * as Styled from './ButtonChip.styled';
import { ButtonChipProps } from './types';

const ButtonChip: FC<ButtonChipProps> = ({ children, active, ...rest }) => {
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const isActiveToggle = () => setIsActive((prev) => !prev);

  return (
    <Styled.Chip
      className={cx('', {
        active: isActive,
      })}
      onClick={isActiveToggle}
      disabled={active}
      {...rest}
    >
      {children}
    </Styled.Chip>
  );
};

export default ButtonChip;
