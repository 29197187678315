import { ScButton } from 'components/UI/Button';
import { show } from 'components/globalStyles/animation';

import styled from 'styled-components/macro';

interface ScBlockProps {
  half?: boolean;
  small?: boolean;
  loading?: boolean;
}

export const ScBlock = styled.div<ScBlockProps>`
  min-height: ${({ small }) => (small ? 'fit-content' : '300px')};
  height: fit-content;
  width: ${({ small }) => (small ? 'fit-content' : '100%')};
  margin: ${({ small }) => (small ? '0 auto' : '0')};
  display: flex;
  flex-direction: ${({ half }) => (half ? 'row' : 'column')};
  min-width: 500px;
  padding: ${({ theme }) =>
    theme.spacing['frame-y'] + ' ' + theme.spacing['frame-x']};
  position: relative;
  background: ${({ theme }) => theme.colors.background.foreground};
  border-radius: ${({ theme }) => theme.radius.frame};
  border: 1px solid ${({ theme }) => theme.colors.border.system};
  transition: all ease 0.3s;
  animation: ${show} 1s ease;
  &:before {
    content: '';
    opacity: ${({ loading }) => (loading ? '1' : '0')};
    pointer-events: ${({ loading }) => (loading ? 'auto' : 'none')};
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: ${({ theme }) => theme.loaderBg};
    border-radius: ${({ theme }) => theme.radius.frame};
    top: 0;
    left: 0;
    transition: all ease 0.3s;
  }
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    padding: 16px 12px;
    width: 100%;
    min-width: auto;
    min-height: 0;
    flex-direction: column;
  }
`;

export const ScBlockPart = styled.div<{ loading?: boolean }>`
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    width: 100%;
  }
  &:before {
    content: '';
    opacity: ${({ loading }) => (loading ? '1' : '0')};
    pointer-events: ${({ loading }) => (loading ? 'auto' : 'none')};
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: ${({ theme }) => theme.loaderBg};
    border-radius: ${({ theme }) => theme.radius.frame};
    top: 0;
    left: 0;
    transition: all ease 0.3s;
  }
`;

interface ScBlockTitleProps {
  small?: boolean;
}

export const ScBlockTitle = styled.h2<ScBlockTitleProps>`
  font: 500 ${({ small }) => (small ? '18px/21px' : '40px/47px')}
    ${({ theme }) => theme.mainFont};
  color: ${({ theme }) => theme.colors.text.primary};
  letter-spacing: 0.1px;
  margin-bottom: ${({ small }) => (small ? '12px' : '36px')};

  small {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: 14px;
    font-weight: 300;
  }
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
    small {
      line-height: 14px;
    }
  }
`;
export const ScBlockSubTitle = styled.h4`
  font: 500 18px/20px ${({ theme }) => theme.mainFont};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const ScErrorBlock = styled(ScBlock)`
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text.primary};
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    max-width: none;
    padding: 0;
    background: transparent;
    border: none;
    border-radius: 0;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    margin: 26px;
    text-align: center;
  }
`;
export const ScErrorBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 36px 0;
  color: ${({ theme }) => theme.colors.text.primary};
  p {
    margin: 20px auto;
  }
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    svg {
      max-width: 168px;
      height: auto;
      margin-bottom: 41px;
    }
    h3 {
      font-size: 28px;
      line-height: 32px;
      margin: 0 0 12px 0;
    }
    p {
      text-align: center;
      margin: 0 0 36px 0;
    }
    ${ScButton} {
      width: 100%;
      font-size: 18px;
      line-height: 21px;
    }
  }
`;

export const ScEmptyTableBlock = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 600px;
  color: ${({ theme }) => theme.colors.text.primary};
  h2 {
    font-size: 48px;
    line-height: 55px;
    margin-bottom: 13px;
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      font-weight: 500;
      font-size: 32px;
      line-height: 37px;
      margin-bottom: 20px;
    }
  }
  p {
    font-size: 16px;
    line-height: 20px;
    padding: 0 20px;
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      font-size: 14px;
    }
  }
`;
