import { formatTypographyToFont } from 'helpers/typography';

import styled from 'styled-components/macro';

export const Chip = styled.button`
  cursor: pointer;
  background-color: transparent;
  border-radius: ${({ theme }) => theme.radius.layer};
  border: 1px solid ${({ theme }) => theme.colors.border.separator};
  padding: 4px 16px;
  font: ${({ theme }) =>
    formatTypographyToFont(theme.typography['paragraph-medium'])};
  color: ${({ theme }) => theme.colors.text.primary};
  transition: 0.3s ease-in-out all;
  &:active,
  &.active {
    background-color: ${({ theme }) => theme.colors.layer.layerBrand};
    border: 1px solid ${({ theme }) => theme.colors.layer.layerBrand};
  }
`;
